.data-privacy{
    margin: 20px 0;
    .data-privacy-header{
        h4{
            margin-top: 30px;
            font-weight: 600;
        }

        p{
            margin-top: 20px;
        }
    }

    h5{
        font-weight: 600;
    }
    
    
}