.upload-form {
  margin: 20px 0;

  .upload-form-content {
    text-align: left;
    padding: 20px 20px;
    background-color: #F3EFEF;
    margin: 20px 0;
    border-radius: 20px;
  }

  .submit-button {
    background-color: #377F80;
    color: #F3EFEF;
    border: none;
    font-weight: 600;
  }

  .submit-button:hover {
    opacity: 90%;
  }
}

.upload-file-btn {
  border-color: #377F80;
  color: #377F80;
  border: 1px solid #377F80;
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: 500;
}

.upload-file-btn:hover {
  background-color: #377F80;
  color: #F3EFEF;
}

.upload-page {
  .title {
    display: flex;
    /* justify-content: space-between; */
    gap: 30px;
    Button {
      width: 30%;
    }

    .upload-instruction-section {
      /* background-color: rgba(8, 28, 75, 85%);
        color:#F3EFEF; */
      background-color: #F3EFEF;
      min-height: 8vh;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding: 0 20px;
    }

    .upload-instruction-section:hover {
      opacity: 80%;
    }

    h2 {
      text-align: left;
      color: #081C4B;
      font-size: 40px;
      font-weight: 700;
      margin-top: 20px;
    }
  }
}

.eight-rem-cell {
  max-width: 8rem;
}

.six-rem-cell {
  max-width: 6rem;
}

.modality-cell {
  max-width: 3rem;
  overflow: auto;
}