.landing-page {
    font-family: Arial, sans-serif;
}

.intro-section {
    display: flex;
    align-items: center;
    padding: 150px 100px;
    background-image: url('../images/\ main-bg.png');
    background-size: cover;
    background-position: center;
}

.intro-left {
    flex: 1;
    padding-left: 70px;


    h1 {
        font-size: 55px;
        margin-bottom: 20px;
        font-weight: 700;
    }

    h1::first-letter {
        color: #377F80;
    }
    
}

.intro-right {
    flex: 1;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */

    /* img {
        max-width: 100%;
        height: auto;
        border-radius: 21px;
    } */
    p {
        font-size: 1.2em;
        margin-bottom: 30px;
    }
}

.intro-buttons {
    display: flex;
    gap: 20px;
}

.explore-btn,
.signup-btn {
    padding: 12px 32px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 21px;
    border: 2px solid #081C4B;
}

.signup-btn {
    background-color: #081C4B;
    color: white;
}

.explore-btn {
    background-color: white;
    color: #081C4B;
    /* border: 2px solid #081C4B; */
}

.tools-section {
    background: #377F80;
    padding: 150px 100px;
}

.tools-text h2 {
    color: #F3EFEF;
    font-weight: 700;
    font-size: 64px;
}

.tools-text p {
    color: #F3EFEF;
    font-size: 20px;
    font-weight: 400;
}

.card-section {
    padding: 20px;
    position: relative;
}

.cards-container {
    display: flex;
    gap: 30px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* Hide scrollbar for Internet Explorer and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
}

.cards-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.navigation-arrows {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.arrow {
    background: transparent;
    border: none;
    margin: 0 10px;
}

/* .arrow:hover { */
/* background-color: #0056b3; */
/* } */

.sponsors-section {
    background: #081C4B;
    padding: 50px 100px;
}

.sponsors-section h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: white;
}

.logo-section {
    padding: 50px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 30px;
}

.logo-section img {
    height: 80px;
    width: auto;
}

.how-section {
    display: flex;
    /* align-items: center; */
    padding: 150px 100px;
    background: #F3EFEF;
}

.how-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-left img {
    max-width: 100%;
    height: auto;
    border-radius: 21px;
}

.how-right {
    flex: 1;
    padding-left: 30px;
}

.how-right h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 96px;
    text-align: left;
    color: #081C4B;
    margin-bottom: 25px;
}

/* .how-right p {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
} */