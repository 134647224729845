.card {
    width: 24rem;
    height: 18rem;
    perspective: 1000px;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 21px;
    /* background: none; */
    border: none;
    /* padding: 20px 20px; */
    
}

.card-bg {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 21px;
    height: auto;
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 1.5rem 1.5rem;
    /* position: relative; */
}

.card-front {
    color: black;
}

.card-back {
    color: black;
    transform: rotateY(180deg);
}