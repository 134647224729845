@import 'bootstrap/dist/css/bootstrap.min.css';

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  min-height: 60vh;
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-feedback {
  display: flex;
}

@media (min-width: 768px) {
  .center-wrapper {
    width: 50%;
    /* Set to a certain percentage of the viewport width */
    margin: 0 auto;
    /* Center the component horizontally */
  }

  .exception {
    width: 65%;
  }
}

.bg-body-tertiary {
  min-height: 100px;
}

/* override default navbar's color */
.navbar,
.navbar-default {
  background-image: linear-gradient(to bottom, #F3EFEF 0, #F3EFEF 100%) !important;
}

.navbar-nav {
  gap: 15px;
}

.header-brand {
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  padding: 0 0;
  margin-left: 10%;
}

.header-brand img {
  max-width: 12rem;
  height: auto;
}

.header-button {
  margin-left: 20px;
  background-color: #377F80;
  border-radius: 21px;
  padding: 5px 30px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
}

.header-button:hover {
  background-color: #377F80;
  opacity: 90%;
}

.numbered-list li {
  margin-bottom: 10px;
}

.cell {
  max-width: 70px;
  max-height: 40px;
  white-space: nowrap;
  overflow: hidden;
}

.cell:hover {
  white-space: initial;
  overflow: auto;
}