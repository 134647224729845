.page-footer {
    background: #081C4B;
    color: white;
    padding-bottom: 10px;
}

.footer-brand {
    padding: 0px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-brand img {
    max-width: 60%;
    height: auto;
}

.footer-brand h5 {
    padding: 10px 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 59.63px;
    text-align: left;
}

.footer-section {
    text-align: left;
}

.footer-section h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
}

.footer-section li {
    padding: 10px 0;
}

.footer-section a {
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 22.77px;
    text-align: left;
}

.footer-section a:hover,
a:active {
    color: white;
    text-decoration: underline;
}

.footer-copyright {
    height: 50px;
    background: #F3EFEF;
    color: black;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}