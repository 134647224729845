.login-form-div {
    padding: 4rem 0;
}

.login-form {
    flex: 1;
    margin-bottom: 1rem;
    background-color: #F3EFEF;
    padding: 2rem 4rem;
    border-radius: 1rem;

    h2 {
        margin-bottom: 20px;
        color: #081C4B;
        font-weight: 700;
        font-size: 40px;
    }

    div {
        margin-bottom: 12px;
    }
}


.disclaimer-label {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    font-size: 1rem;
    color: #081C4B;
}

.disclaimer-label input {
    margin-right: 0.3rem;
    height: 0.8rem;
    padding-top: 0.5rem;
    accent-color: #081C4B;
}

.login-btns {
    margin-top: 1.5rem;
}

.signin-button {
    margin-right: 0.7rem;
    background-color: #377F80 !important;
    border: none;
}

.signin-button:hover {
    background-color: rgba(55, 127, 128, 0.9) !important;
}

.forget-pwd-link {
    text-align: right;
    float: right;
    color: #081C4B;
}

.signup-link {
    padding-left: 0.8rem;
    color: #377F80;
    font-size: 1.1rem;
}

.green-link {
    color: #377F80;
}