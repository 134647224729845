.how-it-works {
    .howitworks-intro {
        display: flex;
        align-items: center;
        padding: 100px 100px;
        min-height: 70vh;
    }

    .intro-left {
        flex: 1;
        padding-right: 20px;
    }

    .intro-left h2 {
        font-size: 4rem;
        margin-bottom: 20px;
        color: #081C4B;
        font-weight: 700;
    }

    .intro-left p {
        font-size: 1.2em;
        margin-bottom: 30px;
    }

    .intro-right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .for-section {
        background: #377F80;
        padding: 100px 100px;
        min-height: 70vh;
    }

    .for-section h2 {
        color: #F3EFEF;
        font-weight: 700;
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    .for-section p {
        color: #F3EFEF;
        font-size: 1.2rem;
        font-weight: 400;
    }

    .security-section {
        padding: 50px 100px;
        min-height: 70vh;
        h2 {
            color: #F3EFEF;
            font-weight: 700;
            font-size: 4rem;
            margin-bottom: 2rem;
            color: #081C4B;
        }

        ul {
            max-width: 70vh;
        }
    }
}