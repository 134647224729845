.organ-volume-calculator {
    .ovc-section {
        display: flex;
        align-items: center;
        padding: 150px 100px;
        background: #377F80;
    }

    .ovc-left {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ovc-left img {
        max-width: 100%;
        height: auto;
        border-radius: 1.2rem;
    }

    .ovc-right {
        flex: 1;
        display: column;
        padding-left: 30px;
        color: #F3EFEF;
    }

    .ovc-right h2 {
        font-size: 56px;
        font-weight: 700;
        line-height: 96px;
        text-align: left;
        margin-bottom: 25px;
    }

    .ovc-right p {
        font-size: 24px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        margin-bottom: 40px;
    }

    .ovc-right button {
        padding: 10px 70px;
        font-size: 24px;
        font-weight: 500;
        border: none;
        border-radius: 17px;
        background-color: #081C4B;
        color: white;
    }


    .instructions-section {
        padding: 100px 100px;
        min-height: 70vh;

        h2 {
            color: #081C4B;
            font-weight: 700;
            font-size: 64px;
            margin-bottom: 50px;
        }

        .cards-section {
            display: flex;
            align-items: center;
            gap: 5%;
        }

        .instruction-card {
            background-color: #F3EFEF;
            padding: 40px 50px;
            border-radius: 40px;
            min-height: 45vh;

            h3 {
                color: #081C4B;
                font-weight: 600;
                font-size: 40px;
                margin-bottom: 20px;
            }

            p {
                font-size: 20px;
            }
        }
    }

    .testimonials-section {
        padding: 50px 100px;
        min-height: 70vh;
        background-color: #081C4B;
        color: #F3EFEF;
        h2 {
            color: #F3EFEF;
            font-weight: 700;
            font-size: 64px;
            margin-bottom: 40px;
        }

        p {
            font-size: 24px;
        }
    }

}