.instructions{
    margin: 30px 0;

    h4 {
        color: #081C4B;
        font-weight: 600;
    }
    
    .steps{
        margin-top: 20px;
        padding: 20px 20px;
        background-color: #F3EFEF;
        border-radius: 15px;

        img{
            max-width: 50%;
            height: auto;
            border-radius: 10px;
        }

        .filename-list{
            font-size: 14px;
        }

        .img-section{
            display: flex;
        }
    }

    .notes{
        font-size: 12px;
    }
}